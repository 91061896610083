:root {
  /* --color-accent: #ffffaa;
  --color-secondary: red;
  --color-standout: #e1c0bb;
  --color-highlight: #d2e0e9;
  --color-highlight2: #f5fcfc; */

  --color-accent: #116466;
  --color-secondary: #d1e8e2;
  --color-standout: #ffcb9a;
  --color-highlight: #d9b08c;
  --color-highlight2: #2c3531;
}

/* .black-3c85d {
  background-color: #116466;
  color: white;
}

.white-1e1d7 {
  background-color: #d1e8e2;
  color: gray;
} */

.btn {
  background-color: var(--color-secondary);
  outline: 0;
  border-width: 0;
  color: black;
  border-radius: 0;
  vertical-align: middle;
}

.btn.active {
  border: 1px solid var(--color-accent);
}

.btn:hover {
  background-color: var(--color-highlight2);
  color: white;
}

.btn.btn-primary {
  background-color: var(--color-accent);
  outline: 0;
  border-width: 0;
  color: white;
}

.btn.btn-primary:hover {
  background-color: var(--color-secondary);
  color: black;
}

.btn.btn-link {
  background-color: transparent;
}

.btn.btn-link:hover {
  color: black;
}

.btn.btn-transp {
  background-color: transparent;
}
.btn.btn-transp:hover {
  background-color: var(--color-highlight2);
}

.btn.btn-small {
  font-size: 0.8rem;
  padding: 0.175rem 0.75rem;
}
.btn.btn-small > i {
  font-size: 1rem;
}

.progress-horizontal {
  display: block;
  width: 100%;
  height: 3px;
  margin-bottom: 3px;
  margin-top: -3px;
  background-color: rgba(0, 0, 0, 0);
}
.bar-horizontal {
  display: none;
}
.bar-horizontal.active {
  display: block;
  width: 40%;
  height: 100%;
  position: relative;
  background: linear-gradient(270deg, #ffffff00, #4355aa00, #ffffff00);
  animation: ProgressAnimation 1s linear infinite;
  animation-delay: 500ms;
}

@keyframes ProgressAnimation {
  0% {
    background: linear-gradient(270deg, #ffffff00, #4355aaaa, #ffffff00);
    left: 0%;
  }
  50% {
    background: linear-gradient(270deg, #ffffff00, #4355aaaa, #ffffff00);
    left: 60%;
  }
  100% {
    background: linear-gradient(270deg, #ffffff00, #4355aaaa, #ffffff00);
    left: 0%;
  }
}

.flip-horizontal {
  transform: rotateZ(180deg);
}

.showOnHoverParent {
  position: relative;
}
.showOnHoverParent > .showOnHoverChild {
  display: none;
}

.showOnHoverParent:hover > .showOnHoverChild {
  display: block;
}

.moveList .deleteButton {
  position: absolute;
  left: 90%;
  top: 50%;
  transform: translateY(-60%);
}

.notesList .list-group-item {
  padding: 0.2rem 0.5rem;
}

.notesList .list-group-item p {
  word-break: break-all;
}

.notesList .deleteButton {
  position: absolute;
  right: 0.5rem;
  top: 0.8rem;
  transform: translateY(-50%);
}

.profileInline .profilePic {
  height: 40px;
}

.profileInline .username {
  margin-right: 10px;
}

.modal {
  display: block;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-body {
  max-height: 500px;
  overflow-y: auto;
}

.font-smaller {
  font-size: x-small;
}

.list-group-item {
  background-color: transparent;
}

.nav.menubar {
  font-size: small;
  background-color: var(--color-accent);
}

.nav.menubar a,
.nav.menubar button {
  font-size: small;
  font-weight: 500;
  background-color: var(--color-accent);
  color: var(--color-secondary);
}

.nav.menubar *:hover {
  background-color: var(--color-secondary);
  color: var(--color-accent);
}

.moveList {
  width: 3rem;
  display: flex;
  flex-direction: column;
  justify-items: stretch;
}

.moveList button {
  font-size: 1rem;
  padding: 0.1rem 0.3rem;
  text-align: left;
  margin-bottom: 1px;
}

.moveList button.close {
  font-size: 1.5rem;
}

.moveList.text-right button {
  text-align: right;
}

.moveList h6 {
  font-size: 1rem;
}

.boardContainer .board-b72b1 {
  border: 0px;
  box-shadow: 0px 0px 3px black;
  touch-action: none;
}

.boardContainer .board-b72b1 img {
  pointer-events: none;
}

.boardContainer .boardOverlay {
  pointer-events: none;
  position: absolute;
  z-index: 10;
  opacity: 1;
  fill: #008799;
  stroke: #008799;
  overflow: hidden;
}

.overlayGroup {
  opacity: 0.6;
  stroke-linecap: square;
  stroke-linejoin: round;
  marker-end: url(#arrowhead);
  marker-start: url(#bulb);
  fill: none;
}

/* width */
::-webkit-scrollbar {
  width: 3px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.tabGroup button {
  padding: 0.4rem 1rem;
  border: 0px;
  border-bottom: 1px solid var(--color-accent);
  color: #555;
}

.tabGroup button.active {
  border-bottom-width: 4px;
  background-color: var(--color-secondary);
  color: #000;
  font-weight: 400;
}

.tabGroup button:focus {
  outline: 0;
}

.tabContent {
  overflow: auto;
}

.tabView {
  border: 1px dotted var(--color-secondary);
}

.searchResults .list-group-item {
  font-size: 0.8rem;
  padding: 0.1rem 0.5rem;
  word-break: break-all;
  border: 0;
  border-radius: 0;
  border-bottom: 1px dotted var(--color-secondary);
}

.searchResults .list-group-item:hover {
  background-color: var(--color-secondary);
}

.publishedPositions .btn {
  background-color: var(--color-secondary);
  padding: 0.1rem 0.3rem;
}

.gameList .gameListItem:last-child {
  border-bottom: 0px;
}

.gameList .gameListItem {
  cursor: pointer;
}
.gameList .gameListItem:hover {
  background-color: var(--color-secondary);
}

.gameListItem .colorBox {
  display: inline-block;
  margin-right: 5px;
  border: 1px solid black;
  background-color: white;
  height: 0.8rem;
  width: 0.8rem;
}

.gameListItem .colorBox.black {
  background-color: black;
}
